
import Vue from 'vue';
import validationRules from '@/validation-rules';

export default Vue.extend({
  data(): {
    rules: typeof validationRules;
  } {
    return {
      rules: validationRules,
    };
  },
});
