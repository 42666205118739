
import Vue from 'vue';
import initializeSitchButtons from 'sitch-embed';
import { hardReload } from '@/util-functions/misc-utils';

export default Vue.extend({
  mounted() {
    initializeSitchButtons();
  },
  methods: {
    fixErrors() {
      hardReload();
    },
  },
});
